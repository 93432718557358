module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Spark PiCO","short_name":"Spark PiCO","dir":"ltr","lang":"en-US","orientation":"any","start_url":"/","scope":"/","background_color":"#fe6253","theme_color":"#fe6253","display":"standalone","description":"A highly scalable & plug-able, Progressive Web Application foundation with the best Developer Experience.","icon":"src/images/logoSpark.png","icons":[{"src":"src/images/logoSpark.png","sizes":"192x192","type":"image/png"},{"src":"src/images/logoSpark.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae889965e029215a5a447244b61e92d0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"devKey":"gGte85X0vWsjv13lGZwAR5jmpyMN0yFl","prodKey":"gGte85X0vWsjv13lGZwAR5jmpyMN0yFl","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6FYTK33V30"],"pluginConfig":{"head":true}},
    }]
