/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PlanCard from 'src/styles/styledComponents/planCard';
import Button from 'src/styles/styledComponents/button';
import { graphql, useStaticQuery } from 'gatsby';
import { getFrontmatter } from 'src/utility/helpers';
import ReactTooltip from 'react-tooltip';
import PlansCardHolder from 'src/styles/styledComponents2/plansCardHolder';
import {
  Container,
  MainContent,
  CardContainer,
} from '../../styles/components/plan/plansIntro';
import Title from '../../styles/styledComponents/title';
import HorizontalLine from '../../styles/styledComponents/horizontalLine';
import { SwitchInput, SwitchLabel, SwitchButton } from '../../styles/styledComponents2/toggleButton';
import { isMobileDevice } from 'src/utility/helpers';
import { planIds } from '../../utility/constants';
import InfoModal from './infoModal';

const PlansIntro = (currentPlan = null) => {
  const data = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { section_name: { eq: "plans_intro" } } }
        ) {
          nodes {
            frontmatter {
              title
              topic
              description
              navigation_button
              additional_description
              plans {
                actual_price
                button
                button_monthly
                link
                description
                discount_duration
                pay_now_label
                pay_now_label_with_discount
                discount_text
                discount_text_monthly
                discount_text_annually_with_discount
                discount_text_monthly_with_discount
                label
                name
                name_monthly
                name_monthly_with_discount
                name_annually_with_discount
                features_list {
                  tooltip_text
                  feature_texts
                }
                navigation_button
                modal_data {
                  cancel_button
                  content
                  ok_button
                  title
                }
                is_monthly_plan_active
                is_image_shown
                is_recommended
                is_pay_now_label_active
              }
              image {
                  base
              }
            }
          }
        }
      }
    `),
    {
      title,
      description,
      plans,
      image,
    } = getFrontmatter(data),
    basicPlan = plans[0],
    premiumPlan = plans[1],
    freeTrial = plans[2],
    platform =
      typeof window !== 'undefined' &&
      window.location.search.includes('platform=')
        ? new URL(window.location.href).searchParams.get('platform')
        : false,
    [isMonthlyPlanActive, setMonthlyPlanActive] = useState(false),
    [hidePlanSwitch, setHidePlanSwitch] = useState(false),
    [isBuyNowActive, setBuyNowActive] = useState(false),
    [planPurchaseInterruptModalVisible, setPlanPurchaseInterruptModalVisible] = useState(false),
    [noPlanChosenModalVisible, setNoPlanChosenModalVisible] = useState(false);

  let headless =
    typeof window !== 'undefined' &&
    window.location.search.includes('headless'),
    access_token =
      typeof window !== 'undefined' &&
        window.location.search.includes('access_token=')
        ? new URL(window.location.href).searchParams.get('access_token')
        : false,
    hideInfoModal =
      typeof window !== 'undefined' &&
      window.location.search.includes('hideInfoModal'),
    authenticated = null,
    hidePremium = null,
    current_plan_type = null,
    plan_id = null;

  useEffect(() => {
    const currentPlanDetails = currentPlan?.currentPlan;
    if( headless && currentPlanDetails?.current_plan_id === null ) {
      if(currentPlanDetails?.chosen_plan_id !== null) {
        setPlanPurchaseInterruptModalVisible(true);
      }
      else if(currentPlanDetails?.chosen_plan_id === null) {
        setNoPlanChosenModalVisible(true);
      }
    } else if (headless && currentPlanDetails?.current_plan_id !== null) {
      if ([planIds.mobilePremiumAnnually, planIds.premiumAnnually, planIds.payNowAnnualSubscription,planIds.payNowAnnualSubscriptionWithDiscount].includes(currentPlanDetails?.current_plan_id)) {
        setMonthlyPlanActive(true);
        setHidePlanSwitch(true);
      } else if ([planIds.premiumMonthly, planIds.payNowMothlySubscription, planIds.payNowMothlySubscriptionWithDiscount].includes(currentPlanDetails?.current_plan_id)) {
        setMonthlyPlanActive(false);
        setHidePlanSwitch(true);
      }
    }
  },[currentPlan])

  if (headless && access_token) {
    plan_id = currentPlan?.currentPlan?.plan_id;
    current_plan_type = currentPlan?.currentPlan?.current_plan_type;

    if (plan_id != null) {
      authenticated = Boolean(plan_id);
    }

    // if (current_plan_type !== null) {
    //   hideCompare = Boolean(current_plan_type);
    // }

    if (current_plan_type == 'premium') {
      hidePremium = Boolean(current_plan_type);
    }
  }

  const getPlanNature = (planId) => {
    if([planIds.freePlan,planIds.freeTrailPlan].includes(planId)){
      return 'free_purchase';
    } else if (currentPlan?.currentPlan?.current_plan_id) {
      if( ![planIds.freePlan,planIds.freeTrailPlan].includes(planId) &&
        ![planIds.freePlan,planIds.freeTrailPlan].includes(currentPlan?.currentPlan?.current_plan_id) ) {
        return 'addon_purchase';
      }
      return 'paid_purchase';
    } else {
      return 'paid_purchase';
    }
  }

  const webPlanSubscriptionFlow = (type= null, planId) => {
    if(headless) {
      if (type === 'remove_cancellation') {
        window.parent.postMessage(
          {
            'url': url || 'https://billing.sparkpico.com?action=remove_cancellation',
            'type': 'remove_cancellation',
            'planId': `${planId}`,
          },
          '*'
        );
      } else if (type === 'cancel_subscription') {
        window.parent.postMessage(
          {
            'url': url || 'https://billing.sparkpico.com?action=cancel_subscription',
            'type': 'cancel_subscription',
            'planId': `${planId}`,
          },
          '*'
        );
      } else if (type === 'manage_subscription') {
        window.parent.postMessage(
          {
            'url': url || 'https://billing.sparkpico.com?action=manage_subscription',
            'type': 'manage_subscription',
            'planId': `${planId}`,
          },
          '*'
        );
      } else {
        window.parent.postMessage(
          {
            'url': `https://billing.sparkpico.com?action=buy&plan_id=${planId}`,
            'type': 'buy',
            'planId': `${planId}`,
            'planNature': getPlanNature(planId),
          },
          '*'
        );
      }
    } else {
      window.location.href = `${process.env.GATSBY_APP_URL}/register?type=buy&plan_id=${planId}&plan_nature=${getPlanNature(planId)}`;
    }
  }

  const mobilePlanSubscriptionFlow = (url, planId) => {
    if(headless) {
      window.location.href = url;
    }
    else {
      const deepLinkUrl = `https://qkbl.adj.st/plan_page_checkout?plan_id=${planId}&adj_t=a3izl4j&adj_fallback=https%3A%2F%2Fapp.sparkpico.com%2Fapp%3Fplan_id%3D${planId}&adj_redirect_macos=https%3A%2F%2Fapp.sparkpico.com%2Fapp%3Fplan_id%3D${planId}`
      window.location.href = deepLinkUrl;
    }
  }

  const getPlanId = ( isMonthlyPlanActive , isBuyNowActive, { currentPlan } ) => {
    let planId = '';
    const currentPlanDetail = currentPlan;
    if (currentPlanDetail?.current_plan_id === planIds.freeTrailPlan ) {
      planId = isMonthlyPlanActive ? planIds.premiumMonthly : planIds.payNowAnnualSubscription;
    } else if (isMonthlyPlanActive) {
      planId = isBuyNowActive ? planIds.payNowMothlySubscriptionWithDiscount : planIds.premiumMonthly;
    } else {
      if(isBuyNowActive) {
        planId = planIds.payNowAnnualSubscriptionWithDiscount;
      } else if (currentPlanDetail?.eligible_for_trial) {
        planId = planIds.premiumAnnually;
      } else if (currentPlanDetail?.eligible_for_trial === false) {
        planId = planIds.payNowAnnualSubscription;
      } else {
        planId = planIds.premiumAnnually;
      }
    }
    return planId;
  }

  const getPlanName = (premiumPlan, isMonthlyPlanActive , isBuyNowActive) => {
    let planName = '';
    if (isMonthlyPlanActive) {
      planName = isBuyNowActive ? premiumPlan?.name_monthly_with_discount : premiumPlan?.name_monthly;
    } else {
      planName = isBuyNowActive ? premiumPlan?.name_annually_with_discount : premiumPlan?.name;
    }
    return planName;
  }

  const getDiscountText = (premiumPlan, isMonthlyPlanActive , isBuyNowActive) => {
    let discountText = '';
    if (isMonthlyPlanActive) {
      discountText = isBuyNowActive ? premiumPlan.discount_text_monthly_with_discount : premiumPlan.discount_text_monthly;
    } else {
      discountText = isBuyNowActive ? premiumPlan.discount_text_annually_with_discount : premiumPlan.discount_text;
    }
    if (isBuyNowActive) {
      return <div className={`price-payment-info ${shouldDisplayDiscountRibbon( currentPlan ) ? '' : 'mb-0'}`}>{discountText}</div>;
    } else {
      discountText = discountText.split('.');
      return <div className={`price-payment-info ${shouldDisplayDiscountRibbon( currentPlan ) ? '' : 'mb-0'}`}>{discountText[0]}.<span
        className={'highlight'}>{discountText[1]}</span></div>;
    }
  }

  const getPayNowLabelText = (premiumPlan , isBuyNowActive) => {
    let payNowLabelText = '';
    if (isBuyNowActive) {
      payNowLabelText = premiumPlan.pay_now_label_with_discount;
    } else {
      payNowLabelText = premiumPlan.pay_now_label;
    }
    return <span className={`discount-details ${isBuyNowActive ? 'savings-active' : '' }`}>{payNowLabelText}</span>;
  }

  const getPriceDetails = (premiumPlan, isMonthlyPlanActive, isBuyNowActive) => {
    let priceDetails = '';
    if (isMonthlyPlanActive) {
      priceDetails = isBuyNowActive ? premiumPlan.actual_price[5] : premiumPlan.actual_price[4];
    } else {
      priceDetails = isBuyNowActive ? premiumPlan.actual_price[2] : premiumPlan.actual_price[1];
    }
    return priceDetails;
  }

  const shouldDisplayDiscountRibbon = ({ currentPlan }) => {
    const currentPlanDetail = currentPlan;
    if( !headless ) {
      return true;
    } else if ( headless && authenticated && currentPlanDetail?.current_plan_id !== null ) {
      return false;
    } else if ( headless && currentPlanDetail?.current_plan_id === null && currentPlanDetail?.chosen_plan_id === null ) {
      return true;
    } else if ( headless && authenticated && currentPlanDetail?.current_plan_id === null ) {
      return true;
    }
    return false;
  }

  return (
    <Container className={'hero'}>
      <div className={'container plans-intro-container'}>
        <MainContent>
          <Title className={'underLine'} color={'#2C3038'}>{title}</Title>
          <p style={{marginTop:'30px'}}>{description}</p>
        </MainContent>

        <CardContainer>
          <PlansCardHolder>
            <PlanCard id={'free'} type={'free'}>
              <div className={'price-container'}>
                <div className={'details'}>
                  <div
                    className={'name'}
                    dangerouslySetInnerHTML={{ __html: basicPlan.name }}
                  />
                  <div className={'plan-description'}>
                    <span className={'price'}>
                      <span className={'dollar'}>
                        {basicPlan.actual_price[0]}
                      </span>
                      {basicPlan.actual_price[1]}
                    </span>
                    <span className={'description'}>
                      <span>{'/'}</span>
                      <span dangerouslySetInnerHTML={{
                        __html: basicPlan.description,
                      }}/>
                    </span>
                  </div>
                </div>
                <HorizontalLine
                  line={'15px'}
                  width={'100%'}
                  height={'2px'}
                  color={'#EBEEF3'}
                />
                <div className={'features'}>
                  {basicPlan.features_list.map((feature, index) => (
                    <div key={index}>
                      <div
                        data-tip={feature.tooltip_text}
                        data-for="plans-free"
                      >
                        <span data-tip={feature.tooltip_text} data-for="plans">
                          <b>{feature.feature_texts[0]}</b>{' '}{feature.feature_texts[1]}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {( !authenticated || currentPlan?.currentPlan?.current_plan_id === null ) && (
                <Button
                  type={'pricing_free'}
                  className={'plans_intro_button'}
                  onClick={() => {
                    const planId = planIds.freePlan;
                    if (platform === 'web' || !isMobileDevice(platform)) {
                      webPlanSubscriptionFlow('', planId);
                    } else {
                      const URL = `https://billing.sparkpico.com?action=buy&plan_id=${planId}&plan_nature=${getPlanNature(planId)}`;
                      mobilePlanSubscriptionFlow(URL, planId);
                    }
                  }}
                >
                  {basicPlan.button}
                </Button>
              )}
            </PlanCard>

            <PlanCard id={'premium'} type={'recommended'}>
              { premiumPlan.is_recommended ? <div className={`recommend-ribbon ${isBuyNowActive ? 'best-seller' : '' }`}>{isBuyNowActive ? 'Best Price' : 'Recommended'}</div> : ''}
              <div className={'header'}>
                {(!hidePlanSwitch && premiumPlan.is_monthly_plan_active) &&
                  <div className={'toggle-switch'}>
                    <span className={'plan-duration'}>Yearly</span>
                    <SwitchInput
                      className="switch-checkbox"
                      type="checkbox"
                      id="test-switch"
                      checked={isMonthlyPlanActive}
                      onChange={e => setMonthlyPlanActive(e.target.checked)}
                    />
                    <SwitchLabel className="switch-label" htmlFor={'test-switch'}>
                      <SwitchButton className="switch-button" />
                    </SwitchLabel>
                    <span className={'plan-duration'}>Monthly</span>
                  </div>}
              </div>
              <div className={'content'}>
                <div className={'price-container'}>
                  <div className={'price-details'}>
                    <div
                      className={`name ${isBuyNowActive ? 'savings-active' : '' }`}
                      dangerouslySetInnerHTML={{ __html: getPlanName(premiumPlan, isMonthlyPlanActive , isBuyNowActive) }}
                    />
                    <div>
                      <span className={'price'}>
                        <span className={'dollar'}>
                          {premiumPlan.actual_price[0]}
                        </span>
                        {premiumPlan.is_monthly_plan_active ?
                          getPriceDetails(premiumPlan, isMonthlyPlanActive , isBuyNowActive)
                          : null}
                        <span className={'duration'}>
                          {premiumPlan.is_monthly_plan_active ?
                            (!isMonthlyPlanActive ? premiumPlan.actual_price[3] : premiumPlan.actual_price[6])
                            : premiumPlan.actual_price[3]}
                        </span>
                      </span>
                    </div>
                  </div>
                  {getDiscountText(premiumPlan, isMonthlyPlanActive , isBuyNowActive)}
                  <HorizontalLine line={'15px'} width={'100%'} height={'2px'} color={'#EBEEF3'} />
                  { premiumPlan.is_pay_now_label_active && shouldDisplayDiscountRibbon( currentPlan ) ?
                    <div className={'discounts'}>
                      <div className={`instant-discount-wrapper ${isBuyNowActive ? 'savings-active' : '' }`}>
                        <SwitchInput
                          className="switch-checkbox"
                          type="checkbox"
                          id="instant-discount-toggle"
                          checked={isBuyNowActive}
                          onChange={e => setBuyNowActive(e.target.checked)}
                        />
                        <SwitchLabel className="switch-label" htmlFor={'instant-discount-toggle'}>
                          <SwitchButton className="switch-button" />
                        </SwitchLabel>
                        {getPayNowLabelText(premiumPlan , isBuyNowActive)}
                      </div>
                    </div> : ''}
                  <div className={`features ${shouldDisplayDiscountRibbon( currentPlan ) ? '' : 'mt-38'}`}>
                    {premiumPlan.features_list.map((feature, index) => (
                      <div key={index}>
                        <div
                          data-tip={feature.tooltip_text}
                          data-for="plans"
                        >
                          <span data-tip={feature.tooltip_text} data-for="plans">
                            <b>{feature.feature_texts[0]}</b>{' '}
                            {feature.feature_texts[1]}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              { (currentPlan?.currentPlan?.current_plan_status?.toLowerCase() === 'changed' && currentPlan?.currentPlan?.scheduled_subscription_info?.plan_id !== '') ? '' :
                  <>
                {(!hidePremium || hidePlanSwitch) && (
                  <Button
                    type={'pricing_premium'}
                    className={'plans_intro_button'}
                    onClick={() => {
                      let planId = getPlanId(isMonthlyPlanActive,isBuyNowActive, currentPlan);
                      if (platform === 'web' || !isMobileDevice(platform)) {
                        webPlanSubscriptionFlow('', planId);
                      } else {
                        planId = planIds.premiumAnnually === planId ? planIds.mobilePremiumAnnually : planId;
                        const URL = `https://billing.sparkpico.com?action=buy&plan_id=${planId}&plan_nature=${getPlanNature(planId)}`;
                        mobilePlanSubscriptionFlow(URL, planId);
                      }
                    }}
                  >
                    {!isMonthlyPlanActive ? premiumPlan.button : premiumPlan.button_monthly}
                  </Button>
                )}
                  </>
              }
              </div>
            </PlanCard>

            <PlanCard id={'trial'} type={'freeTrial'}>
              <div className={'price-container'}>
                <div className={'price-details'}>
                  <div
                    className={'name'}
                    dangerouslySetInnerHTML={{ __html: freeTrial.name }}
                  />
                  <div>
                    <span className={'price'}>
                      <span className={'dollar'}>
                        {freeTrial.actual_price[0]}
                      </span>
                      {freeTrial.actual_price[1]}
                    </span>
                    <span className={'description'}>
                      <span>{'/'}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: freeTrial.description,
                        }}/>
                    </span>
                  </div>
                </div>

                <div className={'price-payment-info'}>{freeTrial.discount_text}</div>

                <HorizontalLine
                  line={'15px'}
                  width={'100%'}
                  height={'2px'}
                  color={'#EBEEF3'}
                />
                <div className={'features'}>
                  {freeTrial.features_list.map((feature, index) => (
                    <div key={index}>
                      <div
                        data-tip={feature.tooltip_text}
                        data-for="plans-free"
                      >
                        <span
                          data-tip={feature.tooltip_text}
                          data-for="plans"
                          dangerouslySetInnerHTML={{
                            __html: feature.feature_texts,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {(!authenticated || currentPlan?.currentPlan?.current_plan_id === null) && (
                <Button
                  type={'pricing_free'}
                  className={'plans_intro_button'}
                  onClick={() => {
                    const planId = planIds.freeTrailPlan;
                    if (platform === 'web' || !isMobileDevice(platform)) {
                      webPlanSubscriptionFlow('buy', planId);
                    } else {
                      const URL = `https://billing.sparkpico.com?action=buy&plan_id=${planId}&plan_nature=${getPlanNature(planId)}`;
                      mobilePlanSubscriptionFlow(URL, planId);
                    }
                  }}
                >
                  {freeTrial.button}
                </Button>
              )}
            </PlanCard>

          </PlansCardHolder>
        </CardContainer>
      </div>
      {!hideInfoModal && (
        <InfoModal
          visible={planPurchaseInterruptModalVisible}
          setVisible={setPlanPurchaseInterruptModalVisible}
          data={premiumPlan.modal_data[1]}
          onOk = {() => {
            const planId = currentPlan?.currentPlan?.chosen_plan_id || currentPlan?.currentPlan?.current_plan_id;
            if (platform === 'web' || !isMobileDevice(platform)) {
              webPlanSubscriptionFlow('', planId);
            } else {
              const URL = `https://billing.sparkpico.com?action=buy&plan_id=${planId}&plan_nature=${getPlanNature(planId)}`;
              mobilePlanSubscriptionFlow(URL, planId);
            }
          }}
        />
      )}
      {!hideInfoModal && (
        <InfoModal
          visible={noPlanChosenModalVisible}
          setVisible={setNoPlanChosenModalVisible}
          data={{
            title: premiumPlan.modal_data[2].title,
            content: premiumPlan.modal_data[2].content,
            button: premiumPlan.modal_data[2].ok_button,
          }}
          onOk = {() => setNoPlanChosenModalVisible(false)}
        />
      )}
      <ReactTooltip place={'bottom'} id={'plans'} effect={'solid'} />
    </Container>
  );
};

export default PlansIntro;
